import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GameContainer from '../../components/GameContainer';
import PageLayout from '../../components/PageLayout';
import * as styles from './index.module.scss';

const TermsPage = () => {
    return (
        <PageLayout>
            <Link to='/'>
                <img src='/skissan.svg' alt='Skissan' />
            </Link>
            <h1>Terms of Service</h1>
            <Helmet>
                <title>Terms of Service - Skissan</title>
            </Helmet>
            <GameContainer textAlign='left' containerSize='large'>
                <div className={styles.termsPage}>
                    {/* <h2>ACCEPTANCE OF TERMS</h2> */}
                    <h2>
                        IMPORTANT -- READ CAREFULLY: BY CREATING AN ACCOUNT OR
                        BY UTILIZING THE SKISSAN SERVICE YOU AGREE TO BE BOUND
                        BY THESE TERMS AND CONDITIONS.
                    </h2>
                    <p>
                        Welcome to the Skissan service, website or mobile
                        application (collectively, the "Service"). Your use of
                        the Service is subject to these Terms of Service and{' '}
                        <Link to='/privacy-policy'>
                            Skissan's Privacy Policy
                        </Link>{' '}
                        (collectively, the “Terms of Service” or “Agreement”).
                        These Terms of Service are a legal agreement between You
                        and Slidesome ehf. (“Slidesome”), for use of the Skissan
                        service. "You" refers to any individual who creates an
                        account on the Service, or, if the Service is being used
                        on behalf of an entity by an individual authorized to
                        agree to such terms on behalf of such entity, then "You"
                        refers to such entity. If You are accessing the Service
                        on behalf of Your employer, you represent and warrant
                        that You have the authority to agree to these Terms of
                        Service on its behalf.
                    </p>
                    <p>
                        If You do not agree with the terms of this Agreement, do
                        not use the Service. Skissan reserves the right to
                        update and change the Agreement from time to time
                        without notice or acceptance by You. The Agreement will
                        also be applicable to the use of the Service on a trial
                        basis. By using the Service, You signify Your
                        irrevocable acceptance of this Agreement. The website
                        and any downloadable software associated with the
                        Service are protected by copyright laws and
                        international copyright treaties, as well as other
                        intellectual property laws and treaties.
                    </p>

                    <h2>DESCRIPTION OF SERVICE</h2>
                    <p>
                        The Service includes, and is limited to, a service, web
                        site, or mobile application that allows You access to
                        and use of a single Skissan Account. "Skissan" referred
                        to herein means an online website that can create play
                        online pictionary. The Service may contain information,
                        data, text, photographs, videos, audio clips, written
                        posts and comments, software, scripts, graphics, and
                        interactive features generated, provided, or otherwise
                        made accessible on or through the Service (collectively,
                        “Content”). Subject to these Terms of Service, Skissan
                        grants to You and each user of the Service a worldwide,
                        non-exclusive, non-sublicensable and non-transferable
                        license to use (i.e., to download and display locally)
                        Content solely for purposes of using the Service. Use,
                        reproduction, modification, distribution or storage of
                        any Content for other than purposes of using the Service
                        is expressly prohibited without prior written permission
                        from us. You shall not sell, license, rent, or otherwise
                        use or exploit any Content for commercial use or in any
                        way that violates any third party right. For the
                        purposes of these Terms of Service, “Content” also
                        includes all User Content (as defined below).
                    </p>
                    <p>
                        A "Skissan Account" or "Account" referred to herein
                        means a service, web site, or mobile application,
                        provided by Skissan, where You may use Skissan to
                        create, update, share, and publish information, data,
                        text, messages or other materials ("User Content").
                    </p>
                    <p>
                        Without limiting the foregoing, the Service is not
                        designed or licensed for use in hazardous environments
                        requiring fail-safe controls, including without
                        limitation operation of nuclear facilities, aircraft
                        navigation/communication systems, air traffic control,
                        and life support or weapons systems.
                    </p>
                    <p>
                        Unless explicitly stated otherwise, any new features
                        that augment or enhance the current Service, including
                        the release of new tools and resources, shall be subject
                        to the Agreement. In order to use the Service, You must
                        obtain access to the World Wide Web, either directly or
                        through devices that access web-based content, and pay
                        any service fees associated with such access. In
                        addition, You must provide all equipment necessary to
                        make such connection to the World Wide Web, including a
                        computer and modem or other access device. You also
                        understand and agree that the service may include
                        certain communications from Skissan, such as service
                        announcements, administrative messages, etc., and that
                        these communications are considered part of the Service
                        and You will not be able to opt out of receiving them.
                        You agree not to access the Service by any means other
                        than through the interfaces that are provided by Skissan
                        for use in accessing the Service. Skissan will provide
                        the Service in accordance with this Agreement. Skissan
                        may at its sole discretion modify the features of the
                        Service from time to time without prior notice.
                    </p>

                    <h2>REGISTRATION</h2>
                    <p>
                        In order to use the Service, You must have a valid
                        Account. To acquire an Account for the Service, You must
                        provide Skissan with an electronic mail address and
                        other information ("Registration Data"). You are
                        responsible for maintaining the confidentiality of the
                        access data for Your Account, and are fully responsible
                        for all activities that occur under Your Account. You
                        agree to (a) immediately notify Skissan of any
                        unauthorized use of Your Account or any other breach of
                        security, and (b) ensure that You exit from Your Account
                        at the end of each session. Skissan cannot and will not
                        be liable for any loss or damage arising from Your
                        failure to comply with this section. In consideration of
                        use of the Service, You agree to: (a) provide true,
                        accurate, current and complete information about
                        Yourself as prompted by the Service's registration form,
                        and (b) maintain and promptly update the Registration
                        Data to keep it true, accurate, current and complete.
                        Skissan assumes no duty to verify such information as
                        further detailed in the CONTENT OF THE SERVICE section
                        of these Terms of Service. If You provide any
                        information that is untrue, inaccurate, not current or
                        incomplete, or Skissan has reasonable grounds to suspect
                        that such information is untrue, inaccurate, not current
                        or incomplete, Skissan has the right to suspend or
                        terminate Your Account and refuse any and all current or
                        future use of the Service (or any portion thereof). By
                        using the Service (and registering an Account on the
                        Service), You represent and warrant that You are at
                        least 13 years of age, and no one under the age of 13
                        may use the Service - except as set forth below under
                        "Kids Under 13 and Skissan". Skissan may, in its sole
                        discretion, refuse to offer the Service to any person or
                        entity and change its eligibility criteria at any time.
                        You are solely responsible for ensuring that these Terms
                        of Service are in compliance with all laws, rules and
                        regulations applicable to you and the right to access
                        the Service is revoked where these Terms of Service or
                        use of the Service is prohibited or to the extent
                        offering, sale or provision of the Service conflicts
                        with any applicable law, rule or regulation.
                    </p>

                    <h2>KIDS UNDER 13 AND SKISSAN</h2>
                    <p>
                        Skissan is not directed to children, and we expect that
                        any use by children will only be done with the guidance,
                        supervision and consent of their parents, guardians
                        and/or authorized school officials. Skissan relies on
                        parents and guardians to ensure that minors only use
                        Skissan if they can understand their rights and
                        responsibilities as laid out in these Terms of Service
                        and in our Privacy Policy.
                    </p>

                    <h2>BILLING/PAYMENT TERMS</h2>
                    <p>
                        Additional groupings of features may be added to the
                        Service and made available to You as a paid upgrade
                        ("Paid Plan"). If You choose to subscribe to a Paid
                        Plan, You shall pay fees (described below) to Skissan.
                        Upon selection of a Paid Plan, You will provide Skissan
                        with the necessary billing information ("Billing Data").
                    </p>
                    <p>
                        Paid Plans can be paid as one-time fee for three days,
                        one month, 6 months or 12 months.
                    </p>

                    <h2>TERMINATION, BREACH, SUSPENSION AND CANCELLATION</h2>
                    <p>
                        If Your Subscription Fee payment is overdue, Skissan
                        will disable Your access to the features provided by the
                        Paid Plan. Skissan may, at its sole discretion, at any
                        time and for any reason, terminate the Service,
                        terminate this Agreement, or suspend or terminate Your
                        Account. In the event of suspension or termination, Your
                        account will be disabled and You may not be granted
                        access to Your Account or any files or other Content
                        (including Your User Content) contained in Your Account,
                        and Skissan may delete Your User Content, although
                        residual copies of information may remain in our system
                        for some time for back-up purposes. In the event of
                        termination, Skissan may also withdraw and at its
                        discretion reallocate the public web address of Your
                        Account.
                    </p>
                    <p>
                        If You terminate Your Account, via means provided for
                        cancellation on the Skissan website, or electronic mail
                        to Skissan, and You request that Skissan delete Your
                        User Content and files contained in Your Account,
                        Skissan will make all reasonable efforts to do so.
                    </p>

                    <h2>REFUND</h2>
                    <p>
                        Skissan provides means for Account and Paid Plan
                        cancellation on the Skissan website. If You cancel Your
                        Account within 90 calendar days of the date of sign up,
                        and You request a refund, Skissan will refund all
                        payments You have made to Skissan within the 90 calendar
                        days prior to the cancellation and refund request.
                    </p>

                    <h2>PROPRIETARY/INTELLECTUAL PROPERTY RIGHTS</h2>
                    <p>
                        Skissan and/or its suppliers, as applicable, retain
                        ownership of all proprietary rights in the Service and
                        in all trade names, trademarks and service marks
                        associated or displayed with the Service. You will not
                        remove, deface or obscure any of Skissan's or its
                        suppliers' copyright or trademark notices and/or legends
                        or other proprietary notices on, incorporated therein,
                        or associated with the Service. You may not reverse
                        engineer, reverse compile or otherwise reduce to human
                        readable form any software associated with the Service.
                    </p>
                    <p>Skissan is a Trademark of Slidesome (Iceland) ehf.</p>

                    <h2>EXPORT RESTRICTIONS</h2>
                    <p>
                        You acknowledge that the Service, or portion thereof may
                        be subject to the export control laws of Iceland. You
                        will not export, re-export, divert, transfer or disclose
                        any portion of the Service or any related technical
                        information or materials, directly or indirectly, in
                        violation of any applicable export law or regulation.
                    </p>

                    <h2>INJUNCTIVE RELIEF</h2>
                    <p>
                        You acknowledge that any use of the Service contrary to
                        this Agreement, or any transfer, sublicensing, copying
                        or disclosure of technical information or materials
                        related to the Service, may cause irreparable injury to
                        Skissan, its affiliates, suppliers and any other party
                        authorized by Skissan to resell, distribute, or promote
                        the Service ("Resellers"), and under such circumstances
                        Skissan, its affiliates, suppliers and Resellers will be
                        entitled to equitable relief, without posting bond or
                        other security, including, but not limited to,
                        preliminary and permanent injunctive relief.
                    </p>

                    <h2>PROPER USE</h2>
                    <p>
                        Skissan does not claim ownership over any User Content
                        submitted on or through the Service. Your User Content
                        belongs to You. However, by uploading any User Content
                        to the Service, You agree that Skissan may store and
                        display (only to You, to the extent that You make such
                        User Content private) Your User Content solely as
                        necessary in connection with the Service. To the extent
                        You choose to share any of Your User Content with other
                        users of the Service, You agree to allow these users
                        (i.e., only the users you specify) to view Your User
                        Content and, to the extent applicable, collaborate with
                        You and Your User Content.
                    </p>
                    <p>
                        You understand that all User Content is the sole
                        responsibility of the person from which such User
                        Content originated. This means that You, and not
                        Skissan, are entirely responsible for all User Content
                        that You upload, post, transmit or otherwise make
                        available via Your Account. Skissan does not control the
                        User Content posted via the Service and, as such, does
                        not guarantee the accuracy, integrity or quality of such
                        User Content.
                    </p>

                    <p>
                        You understand that by using the Service, You may be
                        exposed to User Content that is offensive, indecent or
                        objectionable. Under no circumstances will Skissan be
                        liable in any way for any User Content, including, but
                        not limited to, for any errors or omissions in any
                        Content, or for any loss or damage of any kind incurred
                        as a result of the use of any Content posted,
                        transmitted or otherwise made available via the Service.
                        You acknowledge that Skissan does not pre-screen User
                        Content, but that Skissan and its designees shall have
                        the right (but not the obligation) in their sole
                        discretion to refuse, modify or move any Content that is
                        available via the Service. Without limiting the
                        foregoing, Skissan and its designees shall have the
                        right to remove any User Content that violates the
                        Agreement or is otherwise objectionable. You agree that
                        You must evaluate, and bear all risks associated with,
                        the use of any Content, including any reliance on the
                        accuracy, completeness, or usefulness of such Content.
                        In this regard, You acknowledge that You may not
                        reasonably rely on any Content created by Skissan or
                        submitted to Skissan. You acknowledge and agree that
                        Skissan may preserve User Content and may also disclose
                        User Content if required to do so by law or in the good
                        faith belief that such preservation or disclosure is
                        reasonably necessary to:
                    </p>

                    <p>(a) comply with legal process;</p>

                    <p>(b) enforce the Agreement;</p>

                    <p>
                        (c) respond to claims that any Content violates the
                        rights of third-parties; or
                    </p>

                    <p>
                        (d) protect the rights, property, or personal safety of
                        Skissan, its users and the public.
                    </p>

                    <p>
                        If Skissan discloses User Content to comply with legal
                        process or respond to claims that any User Content
                        violates the rights of third-parties, to the extent
                        permitted by law, regulation or legal process, Skissan
                        agrees to provide You with prompt notice of any such
                        legal or governmental demand and reasonably cooperate
                        with You in any effort to seek a protective order or
                        otherwise to contest such required disclosure.
                    </p>

                    <p>
                        You understand that the technical processing and
                        transmission of the Service, including Your User
                        Content, may involve (a) transmissions over various
                        networks; and (b) changes to conform and adapt to
                        technical requirements of connecting networks or
                        devices.
                    </p>

                    <p>
                        Should User Content be found or reported to be in
                        violation with, but not limited to, the following terms,
                        it will be in Skissan's sole discretion as to what
                        action should be taken. You agree that You will not:
                    </p>

                    <p>
                        (a) upload, post, transmit or otherwise make available
                        any User Content that is unlawful, harmful, threatening,
                        abusive, harassing, tortuous, defamatory, vulgar,
                        obscene, libelous, invasive of another's privacy (up to,
                        but not excluding any address, email, phone number, or
                        any other contact information without the written
                        consent of the owner of such information), hateful, or
                        racially, ethnically or otherwise objectionable;
                    </p>

                    <p>(b) harm minors in any way;</p>

                    <p>
                        (c) impersonate any person or entity, including, but not
                        limited to, a Skissan official, or falsely state or
                        otherwise misrepresent Your affiliation with a person or
                        entity;
                    </p>

                    <p>
                        (d) forge headers or otherwise manipulate identifiers in
                        order to disguise the origin of any User Content
                        transmitted through the Service;
                    </p>

                    <p>
                        (e) upload, post or otherwise transmit any User Content
                        that You do not have a right to transmit under any law
                        or under contractual or fiduciary relationships (such as
                        inside information, proprietary and confidential
                        information learned or disclosed as part of employment
                        relationships or under nondisclosure agreements);
                    </p>

                    <p>
                        (f) upload, post or otherwise transmit any User Content
                        that infringes any patent, trademark, trade secret,
                        copyright, rights of privacy or publicity, or other
                        proprietary rights of any party;
                    </p>

                    <p>
                        (g) upload, post, or transmit unsolicited commercial
                        email or "spam". This includes unethical marketing,
                        advertising, or any other practice that is in any way
                        connected with "spam", including but not limited to (a)
                        sending mass email to recipients who haven't requested
                        email from You or with a fake return address, (b)
                        promoting a site with inappropriate links, titles,
                        descriptions, or (c) promoting Your site by posting
                        multiple submissions in public forums that are
                        identical;
                    </p>

                    <p>
                        (h) upload, post or otherwise transmit any material that
                        contains software viruses or any other computer code,
                        files or programs designed to interrupt, destroy or
                        limit the functionality of any computer software or
                        hardware or telecommunications equipment;
                    </p>

                    <p>
                        (i) interfere with or disrupt the Service or servers or
                        networks connected to the Service, or disobey any
                        requirements, procedures, policies or regulations of
                        networks connected to the Service;
                    </p>

                    <p>(j) "stalk" or otherwise harass another;</p>

                    <p>
                        (k) promote or provide instructional information about
                        illegal activities, promote physical harm or injury
                        against any group or individual, or promote any act of
                        cruelty to animals. This may include, without
                        limitation, providing instructions on how to assemble
                        bombs, grenades and other weapons or incendiary devices.
                    </p>

                    <p>
                        (l) offer for sale or sell any item, good or service
                        that (i) violates any applicable federal, state, or
                        local law or regulation, (ii) You do not have full power
                        and authority under all relevant laws and regulations to
                        offer and sell, including all necessary licenses and
                        authorizations, or (iii) Skissan determines, in its sole
                        discretion, is inappropriate for sale through the
                        Service provided by Skissan;
                    </p>
                    <p>
                        (m) use the Account website as a redirecting/forwarding
                        service to another website;
                    </p>

                    <p>
                        (n) exceed the scope of the Service that You have signed
                        up for; for example, accessing and using the tools that
                        You do not have a right to use, or having humans share
                        User logins, or deleting, adding to, or otherwise
                        changing other people's comments or User Content as an
                        Account holder. If any user is reported to be in
                        violation with the letter or spirit of these terms,
                        Skissan retains the right to terminate such account at
                        any time without further warning.
                    </p>

                    <h2>GENERAL PRACTICES REGARDING USE AND STORAGE.</h2>
                    <p>
                        You agree that Skissan has no responsibility or
                        liability for the deletion of, or the failure to store
                        or to transmit, any User Content and other
                        communications maintained by the Service. You
                        acknowledge that Skissan may establish general practices
                        and limits concerning use of the Service and may modify
                        such practices and limits from time to time. Skissan
                        retains the right to create limits on use and storage at
                        our sole discretion at any time with or without notice.
                        You acknowledge that we reserve the right to log off
                        users who are inactive for an extended period of time.
                    </p>
                    <p>You agree that You will not:</p>
                    <p>
                        (a) upload, post, email, or otherwise transmit any
                        computer routines, files or programs designed to
                        interrupt, destroy or limit the functionality of any
                        computer software or hardware or telecommunications
                        equipment;
                    </p>
                    <p>
                        (b) interfere with or disrupt our Service or networks
                        connected to our website or through the use of our
                        Service, or disobey any requirements, procedures,
                        policies or regulations of networks connected to our
                        website or through the use of our Service, or otherwise
                        interfere with our Service in any way, including through
                        the use of JavaScript or other coding;
                    </p>
                    <p>
                        (c) take any action that imposes an unreasonable or
                        disproportionately large load on our infrastructure; or
                    </p>
                    <p>
                        (d) copy, reproduce, alter, modify, or publicly display
                        any information displayed on the Service (except for
                        Your User Content), or create derivative works from our
                        website (other than from Your User Content), to the
                        extent that such action(s) would constitute copyright
                        infringement or otherwise violate the intellectual
                        property rights of Skissan or any other third party,
                        except with the prior written consent of Skissan or the
                        appropriate third party.
                    </p>

                    <h2>CONTENT OF THE SERVICE.</h2>
                    <p>
                        Skissan takes no responsibility for any third-party
                        Content or User Content (including, without
                        limitation,any viruses or other disabling features), nor
                        does Skissan have any obligation to monitor such
                        third-party Content. Skissan reserves the right at all
                        times to remove or refuse to distribute any Content on
                        the Service, such as Content which violates these Terms
                        of Service. Skissan also reserves the right to access,
                        read, preserve, and disclose any information as it
                        reasonably believes is necessary to (a) satisfy any
                        applicable law, regulation, legal process or
                        governmental request, (b) enforce these Terms of
                        Service, including investigation of potential violations
                        hereof, (c) detect, prevent, or otherwise address fraud,
                        security or technical issues, (d) respond to user
                        support requests, or (e) protect the rights, property or
                        safety of Skissan, its users and the public. Skissan
                        will not be responsible or liable for the exercise or
                        non-exercise of its rights under this Agreement.
                    </p>
                    <p>
                        If Skissan discloses such information to satisfy any
                        applicable law, regulation, legal process or
                        governmental request or to respond to user support
                        requests, to the extent permitted by law, regulation or
                        legal process, Skissan agrees to provide You with prompt
                        notice of any such demand and reasonably cooperate with
                        You in any effort to seek a protective order or
                        otherwise to contest such required disclosure.
                    </p>

                    <h2>INTERNATIONAL USE</h2>
                    <p>
                        Recognizing the global nature of the Internet, You agree
                        to comply with all local rules regarding online conduct
                        and acceptable User Content. Specifically, You agree to
                        comply with all applicable laws regarding the
                        transmission of technical data exported from the United
                        States or the country in which You reside.
                    </p>

                    <h2>NO RESALE OF THE SERVICE</h2>
                    <p>
                        You agree not to reproduce, duplicate, copy, sell,
                        resell or exploit any portion of the Service, use of the
                        Service, or access to the Service without the express
                        permission by Skissan.
                    </p>

                    <h2>YOUR REPRESENTATIONS AND WARRANTIES</h2>
                    <p>
                        You represent and warrant that (a) all of the
                        information provided by You to Skissan to participate in
                        the Service is correct and current; and (b) You have all
                        necessary right, power and authority to enter into these
                        Terms of Service and to perform the acts required of You
                        hereunder.
                    </p>

                    <h2>NO WARRANTIES OR REPRESENTATIONS BY SKISSAN</h2>
                    <p>
                        You understand and agree that the Service is provided
                        "as is" and Skissan, its affiliates, suppliers and
                        Resellers expressly disclaim all warranties of any kind,
                        express or implied, including without limitation any
                        warranty of merchantability, fitness for a particular
                        purpose, non-infringement or bailment of your data on
                        Skissan's servers. Skissan, its affiliates, suppliers
                        and Resellers make no warranty or representation
                        regarding the results that may be obtained from the use
                        of the Service, the security of the Service, or that the
                        Service will meet any user's requirements. Use of the
                        Service is at Your sole risk. You will be solely
                        responsible for any damage to You resulting from the use
                        of the Service. The entire risk arising out of use,
                        security or performance of the Service remains with You.
                        No oral or written information or advice given by
                        Skissan or its authorized representatives shall create a
                        warranty or in any way increase the scope of Skissan's
                        obligations. Without limiting the foregoing, the Service
                        is not designed or licensed for use in hazardous
                        environments requiring fail-safe controls, including
                        without limitation operation of nuclear facilities,
                        aircraft navigation/communication systems, air traffic
                        control, and life support or weapons systems. Without
                        limiting the generality of the foregoing, Skissan, its
                        affiliates, suppliers and Resellers specifically
                        disclaim any express or implied warranty of fitness for
                        such purposes.
                    </p>

                    <h2>INDEMNITY</h2>
                    <p>
                        You agree to indemnify, defend and hold harmless
                        Skissan, its affiliates, officers, directors, employees,
                        consultants, agents, suppliers and Resellers from any
                        and all third party claims, liability, damages and/or
                        costs (including, but not limited to, attorneys fees as
                        and when incurred) arising from Your use of the Service,
                        Your use of Your Account, Your violation of these Terms
                        of Service or the infringement or violation by You or
                        any other User of Your Account, of any intellectual
                        property relating to the Service (including without
                        limitation Your User Content) or other right of any
                        person or entity.
                    </p>

                    <h2>MODIFICATIONS TO SERVICE</h2>
                    <p>
                        Skissan reserves the right at any time and from time to
                        time to modify or discontinue, temporarily or
                        permanently, the Service (or any part thereof) with or
                        without notice at any time. You agree that Skissan shall
                        not be liable to You or to any third party for any
                        modification, suspension, termination or discontinuance
                        of the Service.
                    </p>

                    <h2>NO AGENCY</h2>
                    <p>
                        No agency, partnership, joint venture, employee-employer
                        or franchiser-franchisee relationship between You and
                        Skissan Software is intended or created by these Terms
                        of Service.
                    </p>

                    <h2>LIMITATION OF LIABILITY</h2>
                    <p>
                        In no event will Skissan or its affiliates, suppliers or
                        Resellers be liable for any special, incidental,
                        indirect, exemplary or consequential damages whatsoever
                        (including, without limitation, damages for loss of
                        business profits, business interruption, loss of
                        business information, or any other pecuniary loss or
                        damage) arising out of the use of or inability to use
                        the Service, or the provision of or failure to provide
                        technical or other support service, whether arising in
                        tort (including negligence) contract or any other legal
                        theory, even if Skissan, its affiliates, suppliers or
                        Resellers have been advised of the possibility of such
                        damages. In any case, Skissan's, its affiliates',
                        suppliers' and Resellers' maximum cumulative liability
                        and Your exclusive remedy for any claims arising out of
                        or related to this Agreement will be limited to the
                        amount actually paid by You for the Service (if any) in
                        the previous twelve (12) months.
                    </p>

                    <h2>WAIVER AND SEVERABILITY</h2>
                    <p>
                        Failure by either party to exercise any of its rights
                        under, or to enforce any provision of, this Agreement
                        will not be deemed a waiver or forfeiture of such rights
                        or ability to enforce such provision. If any provision
                        of this Agreement is held by a court of competent
                        jurisdiction to be illegal, invalid or unenforceable,
                        that provision will be amended to achieve as nearly as
                        possible the same economic effect of the original
                        provision and the remainder of this Agreement will
                        remain in full force and effect.
                    </p>

                    <h2>STATUTE OF LIMITATIONS</h2>
                    <p>
                        You agree that regardless of any statute or law to the
                        contrary, any claim or cause of action arising of or
                        related to use of Skissan services or the Terms of
                        Service must be filed within one (1) year after such
                        claim or cause of action arose or be forever barred.
                    </p>

                    <h2>ENTIRE AGREEMENT/GENERAL PROVISIONS</h2>
                    <p>
                        This Agreement embodies the entire understanding and
                        agreement between the parties respecting the subject
                        matter of this Agreement and supersedes any and all
                        prior understandings and agreements between the parties
                        respecting such subject matter. Skissan may change the
                        terms of this Agreement at any time by posting modified
                        terms on its website. This Agreement has been prepared
                        in the English Language and such version shall be
                        controlling in all respects and any non-English version
                        of this Agreement is solely for accommodation purposes.
                        Any and all rights and remedies of Skissan upon Your
                        breach or other default under this Agreement will be
                        deemed cumulative and not exclusive of any other right
                        or remedy conferred by this Agreement or by law or
                        equity on Skissan, and the exercise of any one remedy
                        will not preclude the exercise of any other. The
                        captions and headings appearing in this Agreement are
                        for reference only and will not be considered in
                        construing this Agreement. Notices to You may be made
                        via either email or regular mail. The Service may also
                        provide notices of changes to the Agreement or other
                        matters by displaying notices or links to notices to You
                        generally on the Service. All notices or other
                        correspondence to Skissan under this Agreement must be
                        sent to the following electronic mail address for such
                        purpose:{' '}
                        <a href='mailto:support@slidesome.com'>
                            support@slidesome.com
                        </a>
                    </p>

                    {/* <h2>Update</h2>
                    <p>
                        
                    </p> */}
                    <p>
                        Should we update, amend or make any changes to our terms
                        of service, those changes will be posted here.
                    </p>
                </div>
            </GameContainer>
            <div style={{ marginTop: '2rem' }}>
                <Link to='/games'>← Back to games list</Link>
            </div>
        </PageLayout>
    );
};

export default TermsPage;
